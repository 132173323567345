import {Injectable} from '@angular/core';
import {JwtHelperService} from "@auth0/angular-jwt";
import {HttpClient} from "@angular/common/http";
import {KeycloakEventType, KeycloakService} from "keycloak-angular";
import {map, Observable} from "rxjs";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {environment} from "../../environments/environment";
import {TemporaryTokenDTO} from "./dto";

const helper = new JwtHelperService();


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private keycloak: KeycloakService,
              private http: HttpClient) {

    keycloak.keycloakEvents$.subscribe(event => {
      if (event.type == KeycloakEventType.OnAuthRefreshSuccess) {

      }
      if (event.type == KeycloakEventType.OnAuthSuccess) {

      }
      if (event.type == KeycloakEventType.OnAuthLogout) {

        localStorage.removeItem('kc_token');
      }

    })
  }

  getJwtToken(): Observable<string> {
    return fromPromise(this.keycloak.getToken())
  }

  getTenantId(): Observable<string> {
    return this.getJwtToken().pipe(
      map(jwt => helper.decodeToken<any>(jwt)),
      map(jwt => jwt['name'])
    )
  }

  getTenantDisplayName(): Observable<string> {
    return this.getJwtToken().pipe(
      map(jwt => helper.decodeToken<any>(jwt)),
      map(jwt => jwt?.['tenant_display_name'] ?? '')
    )
  }

  getUserGroup(): Observable<string[]> {
    return this.getJwtToken().pipe(
      map(jwt => helper.decodeToken<any>(jwt)),
      map(jwt => {
          return jwt?.['groups'] ?? ''
        }
      )
    )
  }

  isAdmin(){
    return this.getUserGroup().pipe(map(x=> x.includes('admin')))
  }
  getUsername(): Observable<string> {
    return this.getJwtToken().pipe(
      map(jwt => helper.decodeToken<any>(jwt)),
      map(jwt => jwt['name'])
    )
  }

  getUserId(): Observable<string> {
    return this.getJwtToken().pipe(
      map(jwt => helper.decodeToken<any>(jwt)),
      map(jwt => {
        return jwt['userId']
      })
    )
  }

  logout() {

    // this.http.post("http://localhost:8085/realms/triton/protocol/openid-connect/logout?client_id=account", null, {}).pipe().subscribe(x => this.clearToken())
    this.keycloak.logout(window.origin)
  }

  getPublicToken(code: string): Observable<TemporaryTokenDTO> {
    return this.http.post<TemporaryTokenDTO>(environment.graphql_endpoint + `/../public/request-detail/` + code, null)
  }
}
